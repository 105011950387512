<template>
  <div class="container-fluid mt-3">
    <div class="card">
      <div class="card-body pb-4">
        <h2>Заявка № 1011</h2>
        <p class="mb-1">
          <strong>Адрес ОН: </strong>Москва, ш Пятницкое, д. 31, кв.26
        </p>
        <p class="mb-1">
          <strong>Кадастровый номер объекта: </strong>77:08:0002006:4581
        </p>
        <p class="mb-1">
          <strong>Статус заявки: </strong>выполнено
        </p>
        <p class="mb-3">
          <strong>Результат проверки: </strong>рекомендован
        </p>
        <p class="mb-3">
          <a href="/images/myw3schoolsimage.jpg" download class="">
            <svg-icon name="file"></svg-icon>
            Скачать отчет проверки
          </a>
        </p>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="">Комментарий для обратной связи. Напишите нам вопрос и мы свяжемся с вами</label>
            <textarea class="form-control"></textarea>
          </div>
        </div>
        <div class="form-row justify-content-start">
          <button type="submit"
                  class="btn btn-primary"
                  v-on:click="submit">
            Отправить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submit() {
      setTimeout(() => { alert('Комментарий отправлен'); }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-icon--file {
  width: 2.7rem;
  height: 1.9rem;
}
</style>
